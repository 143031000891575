import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import DashboardRoutes from './dashboard';
import SearchRoutes from "./search";
import UserManagementRoutes from './userManagement';
import StudentsRoutes from './students';
import AcademicRoutes from './academic';
import AdminRoutes from './admin';
import BlogRoutes from "./blog"
import KPIRoutes from "./kpi"
import InvoiceRoutes from "./invoice"
import MessageRoutes from "./message"
import SchedulesRoutes from "./schedules"
import UserAttendanceRoutes from "./userAttendance"

import withAdminLayout from '../../layout/withAdminLayout';

const Admin = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Suspense
                fallback={
                    <div className="spin">
                        <Spin />
                    </div>
                }
            >
                <Route path={path} component={DashboardRoutes} />
                <Route path={path} component={SearchRoutes} />
                <Route path={`${path}/user-management`} component={UserManagementRoutes} />
                <Route path={`${path}/user-attendance`} component={UserAttendanceRoutes} />
                <Route path={`${path}/students`} component={StudentsRoutes} />
                <Route path={`${path}/academic`} component={AcademicRoutes} />
                <Route path={`${path}/admin`} component={AdminRoutes} />
                <Route path={`${path}/blogs`} component={BlogRoutes} />
                <Route path={`${path}/kpi`} component={KPIRoutes} />
                <Route path={path} component={InvoiceRoutes} />
                <Route path={path} component={MessageRoutes} />
                <Route path={path} component={SchedulesRoutes} />
            </Suspense>
        </Switch>
    );
};

export default withAdminLayout(Admin);