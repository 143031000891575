import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const specialDays = lazy(() => import('../../container/userAttendance/specialDays'));
const leave = lazy(() => import('../../container/userAttendance/leave'));
const userAttendance = lazy(() => import('../../container/userAttendance/attendances'));
const earlyLeave = lazy(() => import('../../container/userAttendance/earlyLeave'));
const usersForOthers = lazy(() => import('../../container/userAttendance/usersForOthers'));
const yearlyReservedLeaves = lazy(() => import('../../container/userAttendance/yearlyReservedLeaves'));

const UserAttendanceRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/special-days`} component={specialDays}/>
            <Route path={`${path}/leave`} component={leave}/>
            <Route path={`${path}/userAttendance`} component={userAttendance}/>
            <Route path={`${path}/earlyLeave`} component={earlyLeave}/>
            <Route path={`${path}/usersForOthers`} component={usersForOthers}/>
            <Route path={`${path}/yearlyReservedLeaves`} component={yearlyReservedLeaves}/>
        </Switch>
    );
};

export default UserAttendanceRoutes;
