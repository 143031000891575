import { combineReducers } from 'redux';

import authReducer from './authentication/reducers';
import utilitiesReducer from './utilities/reducers';

import roleReducer from './userManagement/roles/reducers';
import userReducer from './userManagement/users/reducers';
import departmentReducer from './userManagement/departments/reducers';
import positionReducer from './userManagement/positions/reducers';

import specialDaysReducer from './userAttendance/specialDays/reducers';
import leaveReducer from './userAttendance/leaves/reducers';
import userAttendanceReducer from './userAttendance/userAttendances/reducers';
import earlyLeaveReducer from './userAttendance/earlyLeaves/reducers';
import usersForOthersReducer from './userManagement/usersForOthers/reducers';

import yearlyReservedLeaveReducer from './userAttendance/yearlyReservedLeaves/reducers';

import criteriaReducer from './kpi/criterias/reducers';
import kpiTeacherReducer from './kpi/kpi-users/reducers';

import logReducer from './userManagement/logs/reducers';
import scheduleReducer from './schedule/reducers';

import studentsReducer from './students/students/reducers';
import cardReducer from './students/card/reducers';
import studentAttendanceReducer from './students/attendance/reducers';
import studentResultsReducer from './students/results/reducers';
import quickStudentReducer from './students/search/reducers';

import allStudentReducer from './students/all-students/reducers';

import routineReducer from './academic/routines/reducers'
import coordinationReducer from './academic/coordinaton/reducers'
import coordinationMockReducer from './academic/coordinaton/mock/reducers'
import NoteReducer from './academic/notes/reducers'
import NoticeReducer from './academic/notices/reducers'
import attendanceReducer from './academic/attendance/reducers'
import paymentsReducer from './academic/payments/reducers'
import transactionsReducer from './academic/transactions/reducers'
import calendersReducer from './academic/calender/reducers'

import resultMockReducer from './academic/result/mock/reducers'
import resultQuarterReducer from './academic/result/quarter/reducers'
import resultHalfYearlyReducer from './academic/result/halfYearly/reducers'
import resultFinalReducer from './academic/result/final/reducers'

import reportsQuarterReducer from './academic/reports/quarter/reducers'
import reportsMockReducer from './academic/reports/mock/reducers'
import reportsFinalReducer from './academic/reports/final/reducers'
import reportsHalfYearlyReportReducer from './academic/reports/halfYearly/reducers'

import performanceQuarterReducer from './academic/performance/reducers'

import classRoomReducer from './academic/setup/classRooms/reducers'
import subjectReducer from './academic/setup/subjects/reducers'
import periodReducer from './academic/setup/periods/reducers'
import sessionReducer from './academic/setup/sessions/reducers'
import sectionsReducer from './academic/setup/sections/reducers'
import classesReducer from './academic/setup/classes/reducers'
import paymentTypeReducer from './academic/setup/paymentTypes/reducers'
import dashboardReducer from './dashboard/reducers'
import invoiceReducer from './invoice/reducers'
import messageReducer from './message/reducers'
import studentsResultAccessReducer from './student-access/reducers'

import careerReducer from './admin/reducers'
import blogReducer from './blogs/reducers'

import ChangeLayoutMode from './themeLayout/reducers';

const rootReducers = combineReducers({
    auth: authReducer,
    utilities: utilitiesReducer,

    umRoles: roleReducer,
    umUsers: userReducer,
    umDepartments: departmentReducer,
    umPositions: positionReducer,

    utSpecialDays: specialDaysReducer,
    utLeaves: leaveReducer,
    utAttendances: userAttendanceReducer,
    utEarlyLeaves: earlyLeaveReducer,
    utUsers: usersForOthersReducer,
    utYearlyReservedLeaves: yearlyReservedLeaveReducer,

    umCriterias: criteriaReducer,
    umKpiTeachers: kpiTeacherReducer,

    umLogs: logReducer,
    umSchedules: scheduleReducer,

    studentsCards: cardReducer,
    studentAttendance: studentAttendanceReducer,
    studentResults: studentResultsReducer,
    quickStudent: quickStudentReducer,

    allStudents: allStudentReducer,
    studentAccess: studentsResultAccessReducer,

    allCareers: careerReducer,
    blogs: blogReducer,

    studentsStudents: studentsReducer,
    academicRoutines: routineReducer,

    academicCoordination: coordinationReducer,
    academicCoordinationMock: coordinationMockReducer,

    academicNote: NoteReducer,
    academicNotice: NoticeReducer,

    academicAttendance: attendanceReducer,
    academicPayments: paymentsReducer,
    academicTransactions: transactionsReducer,
    academicCalenders: calendersReducer,

    academicResultMock: resultMockReducer,
    academicResultQuarter: resultQuarterReducer,
    academicResultHalfYearly: resultHalfYearlyReducer,
    academicResultFinal: resultFinalReducer,

    academicPerformanceQuarter: performanceQuarterReducer,

    academicReportsQuarter: reportsQuarterReducer,
    academicReportsMock: reportsMockReducer,
    academicReportsFinal: reportsFinalReducer,
    academicReportsHalfYearly: reportsHalfYearlyReportReducer,

    academicClassRooms: classRoomReducer,
    academicSubjects: subjectReducer,
    academicPeriods: periodReducer,
    academicSessions: sessionReducer,
    academicClasses: classesReducer,
    academicSections: sectionsReducer,
    academicPaymentTypes: paymentTypeReducer,

    dashboardData: dashboardReducer,
    invoiceData: invoiceReducer,
    messageData: messageReducer,

    ChangeLayoutMode,
});

export default rootReducers;
